/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState, ReactNode } from 'react';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';

export type ColumnConfig<T> = {
  key: keyof T;
  label: string;
  render?: (item: T) => ReactNode;
  sortable?: boolean;
  align?: 'left' | 'right' | 'center';
};

type TableProps<T> = {
  data: T[];
  columns: ColumnConfig<T>[];
  defaultSortKey?: keyof T;
  defaultSortDirection?: 'ascending' | 'descending';
  gridStyleCalc?: (columns: number) => string;
};

const SimpleTable = <T,>(props: TableProps<T>): ReactNode => {
  const { data, columns, defaultSortKey, defaultSortDirection = 'ascending', gridStyleCalc } = props;
  const [sortConfig, setSortConfig] = useState<{ key: keyof T; direction: 'ascending' | 'descending' }>({
    key: defaultSortKey || columns[0].key,
    direction: defaultSortDirection,
  });

  const sortedData = useMemo(() => {
    const sortableData = [...data];

    sortableData.sort((a, b) => {
      if (a[sortConfig.key]! < b[sortConfig.key]!) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key]! > b[sortConfig.key]!) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sortableData;
  }, [data, sortConfig]);

  const sort = (key: keyof T) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key: keyof T) => {
    const direction = sortConfig.key === key ? sortConfig.direction : undefined;
    return <ChevronIcon className="h-5 w-5" type={direction === 'descending' ? ChevronType.UP : ChevronType.DOWN} />;
  };

  const gridStyle = {
    gridTemplateColumns: gridStyleCalc
      ? gridStyleCalc(columns.length)
      : `minmax(150px, 2fr) repeat(${columns.length - 2}, minmax(100px, 1fr)) minmax(150px, 1fr)`,
    alignItems: 'center',
  };

  return (
    <div className="divide-gray-3 min-w-full divide-y">
      <div className={`grid gap-4 whitespace-nowrap px-6 py-3 font-medium`} style={gridStyle}>
        {columns.map((column) => (
          <div
            key={column.key as string}
            className={`cursor-pointer ${column.sortable ? '' : 'pointer-events-none'} ${column.align === 'right' ? 'text-right' : column.align === 'center' ? 'text-center' : 'text-left'}`}
            {...(column.sortable ? mouseAndKeyboardCallbackProps(() => sort(column.key)) : {})}
          >
            {column.label}
            {column.sortable && <span className="ml-2">{getSortDirection(column.key)}</span>}
          </div>
        ))}
      </div>
      <div className="divide-gray-3 divide-y bg-white">
        {sortedData.map((item, index) => (
          <div key={index} className={`hover:bg-background-1 grid gap-4 px-6 py-4`} style={gridStyle}>
            {columns.map((column) => (
              <div
                key={column.key as string}
                className={`${column.align === 'right' ? 'justify-end text-right' : column.align === 'center' ? 'justify-center text-center' : 'justify-start text-left'} flex items-center whitespace-nowrap`}
              >
                {column.render ? column.render(item) : (item[column.key] as string)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimpleTable;
